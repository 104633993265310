import logo from "./logo.jpeg";
import gassan from "./gassan.png";
import youngCapital from "./young-capital.png";

import { useState } from "react";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

const copy = {
  nl: {
    title: "ARC-Heitingatoernooi 2024",
    welcome: "Kies je toernooi om door te gaan:",
    u12_link_title: "Gassan Young Talent Tournament",
    main_link_title: "Young Capital Toernooi",
  },
  en: {
    title: "ARC-Heitingatoernooi 2024",
    welcome: "Choose your tournament to continue:",
    u12_link_title: "Gassan Young Talent Tournament",
    main_link_title: "Young Capital Tournament",
  },
  sv: {
    title: "ARC-Heitingatoernooi 2024",
    welcome: "Välj din turnering för att fortsätta:",
    u12_link_title: "Gassan Young Talent Tournament",
    main_link_title: "Young Capital Turnering",
  },
  de: {
    title: "ARC-Heitingatoernooi 2024",
    welcome: "Wählen Sie Ihr Turnier, um fortzufahren:",
    u12_link_title: "Gassan Young Talent Tournament",
    main_link_title: "Young Capital Turnier",
  },
  fr: {
    title: "ARC-Heitingatoernooi 2024",
    welcome: "Choisissez votre tournoi pour continuer:",
    u12_link_title: "Gassan Young Talent Tournament",
    main_link_title: "Young Capital Tournoi",
  },
  pt: {
    title: "ARC-Heitingatoernooi 2024",
    welcome: "Escolha seu torneio para continuar:",
    u12_link_title: "Gassan Young Talent Tournament",
    main_link_title: "Young Capital Torneio",
  },
  it: {
    title: "ARC-Heitingatoernooi 2024",
    welcome: "Scegli il tuo torneo per continuare:",
    u12_link_title: "Gassan Young Talent Tournament",
    main_link_title: "Young Capital Torneo",
  },
};

function App() {
  polyfillCountryFlagEmojis();

  const [locale, setLocale] = useState(localStorage.getItem("locale") || "nl");

  const handleLocaleChange = (event) => {
    localStorage.setItem("locale", event.target.value);
    setLocale(event.target.value);
  };

  const tournamentDate = new Date("2024-05-08");
  const currentDate = new Date();

  const differenceMs = tournamentDate.getTime() - currentDate.getTime();
  const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  const counter = (
    <div className='w-screen h-screen bg-[#242631] text-white flex items-center justify-center'>
      <div className='text-center leading-loose space-y-3 p-8'>
        <img src={logo} alt='ARC-Heitingatoernooi logo' width='100' className='mx-auto mb-2' />

        <div className='text-2xl font-bold'>Nog {differenceDays} dagen tot het toernooi!</div>
        <div>
          Houd onze{" "}
          <a href='https://arc-heitingatoernooi.nl/' className='underline font-bold'>
            website
          </a>{" "}
          en{" "}
          <a href='https://www.facebook.com/ARCHeitingatoernooi' className='underline font-bold'>
            socials
          </a>{" "}
          in de gaten voor meer informatie.
        </div>
      </div>
    </div>
  );

  const fullApp = (
    <main className='flex flex-col min-h-screen justify-center p-12'>
      <div className='absolute top-4 right-4'>
        <select className='p-2 ml-2' onChange={handleLocaleChange} defaultValue={locale}>
          <option value='nl'>🇳🇱</option>
          <option value='en'>🇬🇧</option>
          <option value='sv'>🇸🇪</option>
          <option value='de'>🇩🇪</option>
          <option value='fr'>🇫🇷</option>
          <option value='pt'>🇵🇹</option>
          <option value='it'>🇮🇹</option>
        </select>
      </div>
      <div className='text-center'>
        <img src={logo} alt='ARC-Heitingatoernooi logo' width='100' className='mx-auto mb-2' />
        <h1 className='text-lg font-bold'>{copy[locale].title}</h1>
        <p>{copy[locale].welcome}</p>
      </div>

      <div className='flex flex-col items-center justify-center mt-12 space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4'>
        <a
          href={`https://heitingatoernooi-2024.vercel.app/?locale=${locale}`}
          className='flex items-center justify-center text-center w-full h-24 px-4 py-2 text-lg font-bold text-white bg-[#B92E29] rounded-lg lg:w-64 hover:bg-[#9b2622]'
        >
          {copy[locale].main_link_title}
        </a>
        <a
          href={`https://heitingatoernooi-u12.vercel.app/?locale=${locale}`}
          className='flex items-center justify-center text-center w-full h-24 px-4 py-2 text-lg font-bold text-white bg-[#B92E29] rounded-lg lg:w-64 hover:bg-[#9b2622]'
        >
          {copy[locale].u12_link_title}
        </a>
      </div>

      <div className="flex items-center mx-auto gap-12 mt-12">
        <div className="w-24 text-center">
          <img src={youngCapital} alt='Gassan' />
        </div>
        <div className="w-24 text-center">
          <img src={gassan} alt='Gassan' />
        </div>
      </div>
    </main>
  );

  return fullApp;
  // return differenceDays > 0 ? counter : fullApp;
}

export default App;
